const networkNode = 
    [
        {
            index: "n1",
            attribute: {
                size: 30,
                label: "Big Dataworks Sdn Bhd", 
                color: "#FFFFFF", 
                type: 'image', 
                image: "./building.svg"
            }
        },
        {
            index: "n2",
            attribute: {
                size: 20,
                label: "Sheriza Zakaria", 
                color: "#FFFFFF", 
                type: 'image', 
                image: "./person.svg"
            }
        },
        {
            index: "n3",
            attribute: {
                size: 30, 
                label: "Puncak Semangat", 
                color: "#FFFFFF", 
                type: 'image', 
                image: "./building.svg"
            }
        },
        {
            index: "n4",
            attribute: {
                size: 30, 
                label: "Puncak Tegap", 
                color: "#FFFFFF", 
                type: 'image', 
                image: "./building.svg"
            }
        },
        {
            index: "n5",
            attribute: {
                size: 20, 
                label: "Syed Azmin", 
                color: "#FFFFFF", 
                type: 'image', 
                image: "./person.svg"
            }
        },
    
    ]


const networkEdge = [
    {
        source: "n1",
        destination: "n2",
        attribute: {
            size: 5,
            label: "Director", 
            color: "#000000", 
            type: "arrow"
        },
    },
    {
        source: "n1",
        destination: "n5",
        attribute: {
            size: 5,
            label: "Director", 
            color: "#000000", 
            type: "arrow"
        }
    }
]


export { networkNode, networkEdge }
import api from "../BackendApi"
import { useMutation } from "react-query"

export const useSearchExistence = () => {
    const {mutate, isLoading, isSuccess, data} = useMutation(payload => api.searchExistence(payload))

    return {mutate, isLoading, isSuccess, data}
}

export const useSearchSubExistence = () => {
    const {mutate, isLoading, isSuccess, isError, status, error, data} = useMutation(payload => api.searchSubExistence(payload))

    return {mutate, isLoading, isSuccess, isError, status, error, data}
}

export const useRetrieveData = () => {
    const {mutate, isLoading, isSuccess, data} = useMutation(payload => api.retrieveData(payload))

    return {mutate, isLoading, isSuccess, data}
}

export const useEntitySearch = () => {
    const {mutate, isLoading, isSuccess, data} = useMutation(payload => api.entitySearch(payload))

    return {mutate, isLoading, isSuccess, data}
}

export const useExpandCheck = () => {
    const {mutate, isLoading, isSuccess, data} = useMutation(payload => api.expandCheck(payload))

    return {mutate, isLoading, isSuccess, data}
}

export const useExpandSubCheck = () => {
    const {mutate, isLoading, isSuccess, data} = useMutation(payload => api.expandSubCheck(payload))

    return {mutate, isLoading, isSuccess, data}
}

export const useValidateToken = () => {
    const {mutate, isLoading, isSuccess, data} = useMutation(payload => api.validateToken(payload))

    return {mutate, isLoading, isSuccess, data}
}

export const useDownloadCompanyFile = () => {
    const {mutate, isLoading, isSuccess, data} = useMutation(payload => api.downloadCompanyFile(payload))

    return {mutate, isLoading, isSuccess, data}
}


// React Flow & D3
export const useRetrieveProfile = () => {
    const {mutate, isLoading, isSuccess, data} = useMutation(payload => api.retrieveProfile(payload))

    return {mutate, isLoading, isSuccess, data}
}

export const useSearchProfileExistence = () => {
    const {mutate, isLoading, isSuccess, data} = useMutation(payload => api.searchProfileExistence(payload))

    return {mutate, isLoading, isSuccess, data}
}

export const useSearchSubProfileExistence = () => {
    const {mutate, isLoading, isSuccess, data} = useMutation(payload => api.searchSubProfileExistence(payload))

    return {mutate, isLoading, isSuccess, data}
}

export const useExpandMainProfile = () => {
    const {mutate, isLoading, isSuccess, data} = useMutation(payload => api.expandMainProfile(payload))

    return {mutate, isLoading, isSuccess, data}
}

export const useExpandSubProfile = () => {
    const {mutate, isLoading, isSuccess, data} = useMutation(payload => api.expandSubProfile(payload))

    return {mutate, isLoading, isSuccess, data}
}

export const useDownloadCompanyProfile = () => {
    const {mutate, isLoading, isSuccess, data} = useMutation(payload => api.downloadCompanyProfile(payload))

    return {mutate, isLoading, isSuccess, data}
}

// Tableau

// LHDN Owner
export const useGetInfo = () => {
    const {mutate, isLoading, isSuccess, data} = useMutation(payload => api.getInfo(payload))
    
    return {mutate, isLoading, isSuccess, data}
}
import React, { useEffect, useState } from 'react'
// import Tree from 'react-d3-tree'
// import './css/custom-tree.css'
import Sidebar from './Sidebar'
import { useParams } from 'react-router-dom'
import jwt from 'jwt-decode'
import { useValidateToken, useRetrieveProfile, useGetInfo } from '../../api/Mutation/mutate'
import { encoding } from '../../api/Encryption/encryption'
import ErrorPage from './ErrorPage'
import Loader from './Loader'

export default function RetrieveCheck(){

  const { id } = useParams()
  const [paymentToken, setPaymentToken] = useState(null)
  // const {mutate, isLoading, isSuccess} = useValidateToken()
  const {mutate, isLoading: isLoading, isSuccess, data: uboData} = useGetInfo()
  const [initialTreeData, setInitialTreeData] = useState(null)
  const [renderPage, setRenderPage] = useState(false)

  let treeData = null
  let showLoader = false

  function handleIcon(allNode, allLink) {
    const newNode = allNode.map((item, index) => ({...item, key: item.id, svg: index !== 0? "https://img.icons8.com/ios-filled/2x/company.png":"https://img.icons8.com/ios-filled/2x/guest-male.png"}))
    console.log(allLink)
    setInitialTreeData({node_data: newNode, link_data: allLink})
  }
  

  useEffect(() => {
    try {
      if (jwt(id)){
        const decodedResult = jwt(id)
   
        if (decodedResult.initialSearch === true) {
          const formInfo = encoding({
            ...decodedResult
          })
      
          let payload = {
            userInformation: formInfo
          }

          mutate(payload, {
            onSuccess: (res) => {
              console.log("Success")
              setRenderPage(true)
              handleIcon(res.data.node_data, res.data.link_data)
            },
            onError: () => {
              console.log("Error on useGetInfo")
              setRenderPage(false)
            },
          })
        }
      }
    } catch(err) {
      setRenderPage(false)
    }
  }, [id])

  if (isSuccess) {
    if (uboData.data !== undefined) {
      treeData = uboData.data
    }
  }

  if (isLoading) {
    showLoader = true
  }

  return (
    <div>

      {renderPage === true && treeData !== null ? 
        
        <Sidebar user_id={uboData.data.user_id} tree_data={initialTreeData} initialSearch={true} payment_token={treeData.payment_token}/>
        :
        <ErrorPage />
      }

      {showLoader && 
        <div>
          <Loader message="Retrieving Information" />
        </div>
      }


    </div>
  )
}
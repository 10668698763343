import React, {useEffect} from "react"
import UserRoute from "./UserRoute"
import TableauRoute from "./TableauRoute"
import SigmaRoute from "./SigmaRoute"
import DthreeRoute from "./DthreeRoute"
import LhdnOwnerRoute from "./LhdnOwnerRoute"
import { Routes, Route } from "react-router-dom"

export default function MainRoute(){
    return (
        <Routes>
            {UserRoute.map((route,index) =>
                <Route key={index} path={route.href} element={route.component} />
            )}

            {TableauRoute.map((route,index) =>
                <Route key={index} path={route.href} element={route.component} />
            )}

            {SigmaRoute.map((route,index) =>
                <Route key={index} path={route.href} element={route.component} />
            )}

            {DthreeRoute.map((route,index) =>
                <Route key={index} path={route.href} element={route.component} />
            )}

            {LhdnOwnerRoute.map((route,index) =>
                <Route key={index} path={route.href} element={route.component} />
            )}

        </Routes>
    )
}
export const graphOption = [
    { value: false, label: 'Drag & Drop' },
    { value: true, label: 'Static' }
    
]

export const linkTypeOption = [
    { value: 'STRAIGHT', label: 'Straight' },
    { value: 'CURVE_SMOOTH', label: 'Curve Smooth' },
    { value: 'CURVE_FULL', label: 'Curve Full' }
]

export const nodeLabelPositionOption = [
    { value: 'bottom', label: 'Bottom' },
    { value: 'top', label: 'Top' },
    { value: 'left', label: 'Left' },
    { value: 'right', label: 'Right' },
    { value: 'center', label: 'Center' }
]
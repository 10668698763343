import React from "react"
import Network from "../components/Dthree/Network"
import Sidebar from "../components/Dthree/Sidebar"
import RetrieveCheck from "../components/Dthree/RetrieveCheck"

let dthreeRoute = [
    {
        name: "network page",
        href: "/d3",
        component: <Network />
    },
    {
        name: "network main",
        href: "/d3-main",
        component: <Sidebar />
    },
    {
        name: "d3",
        href: "/linkage/:id",
        component: <RetrieveCheck />
    },
]

export default dthreeRoute
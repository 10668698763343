import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import jwt from 'jwt-decode'
import { encoding } from '../../api/Encryption/encryption'
import { useValidateToken } from '../../api/Mutation/mutate'
import TableauMonthlySales from './TableauMonthlySales'
import TableauProductSales from './TableauProductSales'
import TableauSegmentSales from './TableauSegmentSales'
import TableauUserSales from './TableauUserSales'
import TableauMydataGross from './TableauMydataGross'
import TableauMydataNet from './TableauMydataNet'
import TableauTargetSales from './TableauTargetSales'
import TableauInfoMonthly from './TableauInfoMonthly'
import TableauInfoYearly from './TableauInfoYearly'
import ErrorPage from './ErrorPage'

export default function MainPage() {
    const { id } = useParams()
    const {mutate, isLoading, isSuccess, data} = useValidateToken()
    let renderPage = false
    let TableauType = null

    useEffect(() => {
        try {
          if (jwt(id)){
            const decodedResult = jwt(id)
       
            const formInfo = encoding({
              ...decodedResult
            })
        
            let payload = {
              userInformation: formInfo
            }
        
            mutate(payload, {
              onSuccess: () => {
                console.log('success validate')
              },
            })
          }
        } catch(err) {
          console.log(err)
        }
    }, [id])

    if (isSuccess) {
      renderPage = true
      TableauType = data.data.graphType
    }

    return (
        <div>
            { renderPage === true && TableauType === 'target sales'? 
                <TableauTargetSales />
                :
                renderPage === true && TableauType === 'gross sales'? 
                <TableauMydataGross />
                :
                renderPage === true && TableauType === 'net sales'?
                <TableauMydataNet />
                :
                renderPage === true && TableauType === 'monthly sales'?
                <TableauMonthlySales />
                :
                renderPage === true && TableauType === 'segment sales'?
                <TableauSegmentSales />
                :
                renderPage === true && TableauType === 'product sales'? 
                <TableauProductSales />
                :
                renderPage === true && TableauType === 'user sales'? 
                <TableauUserSales />
                :
                renderPage === true && TableauType === 'info monthly'? 
                <TableauInfoMonthly />
                :
                renderPage === true && TableauType === 'info yearly'? 
                <TableauInfoYearly />
                :
                <ErrorPage />
            }
        </div>
    )
}
import { useEffect, useState } from "react"
import Graph, { MultiDirectedGraph } from "graphology"
import { SigmaContainer, useLoadGraph, useRegisterEvents } from "@react-sigma/core"
import { useLayoutRandom } from "@react-sigma/layout-random"
import circular from 'graphology-layout/circular'
import circlepack from 'graphology-layout/circlepack'
import noverlap from 'graphology-layout-noverlap';
import random from 'graphology-layout/random'
import rotation from 'graphology-layout/rotation'
import forceLayout from 'graphology-layout-force'
import forceAtlas2 from 'graphology-layout-forceatlas2'
import "@react-sigma/core/lib/react-sigma.min.css"
import { FaUserAlt } from "react-icons/fa"
import { networkNode, networkEdge } from "./Data"
import getNodeProgramImage from "sigma/rendering/webgl/programs/node.image"
import Sigma from "sigma"

const LoadGraph = () => {
  const registerEvents = useRegisterEvents()
  const loadGraph = useLoadGraph()
  const [hovered, setHovered] = useState(null)
  const [getNeighbour, setGetNeighbour] = useState(null)

  const graph = new Graph()

  networkNode.forEach(function(item, index, arr){
    graph.addNode(item.index, item.attribute)
  })

  networkEdge.forEach(function(item, index, arr){
    graph.addEdge(item.source, item.destination, item.attribute)
  })
    
  useEffect(() => { 

    registerEvents({
      enterNode: (event) => {
        setHovered(event.node)
        if(hovered !== null) { 
          console.log(hovered)
          console.log(graph.neighbors(hovered))
          graph.nodes().forEach((node, i) => {
            if(graph.neighbors(hovered).includes(node) && node !== hovered) {
              graph.setNodeAttribute(node, "label", "NEW")
            }
          })
        }
      }
    }) 

    circular.assign(graph)
    noverlap.assign(graph)
    loadGraph(graph)

  }, [registerEvents, hovered])


}

export default function Network() {
  return (
    <div>
        <SigmaContainer 
          style= {
            { 
              height: "100vh",
              width: "100%",
              backgroundColor: "#e0e0e0" 
            }
          }
          settings={{
            nodeProgramClasses: { 
              image: getNodeProgramImage() 
            },
            renderEdgeLabels: true,
            defaultEdgeType: "arrow",
          }}
          >
            <LoadGraph />
        </SigmaContainer>
    </div> 
  )
}
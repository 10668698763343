import React from "react"

export default function HomePage() {
    return (
        <div className="py-60 bg-gradient-to-r from-cyan-500 to-blue-500" >
            <div className="text-center container mx-auto px-6">
                <h2 className="text-5xl font-bold mb-2 text-white">
                    MyData Ultimate Beneficial Owner
                </h2>
                <h3 className="text-3xl mb-8 text-gray-200">
                    Expand anytime and anywhere, pay as you go
                </h3>

                <button className="bg-white font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider">
                    Know more
                </button>
            </div>
        </div>
    )
}
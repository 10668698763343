import React, { useEffect, useState } from 'react'
// import Tree from 'react-d3-tree'
import './css/custom-tree.css'
import Sidebar from './Sidebar'
import { useParams } from 'react-router-dom'
import jwt from 'jwt-decode'
import { useValidateToken, useRetrieveData } from '../../api/Mutation/mutate'
import { encoding } from '../../api/Encryption/encryption'
import ErrorPage from './ErrorPage'
import EntityMain from './EntityMain'
import Loader from './Loader'

export default function GetAllOwner(){

  const { id } = useParams()
  const {mutate, isLoading, isSuccess, data} = useValidateToken()
  const {mutate: mutateData, isLoading: isLoadingData, isSuccess: isSuccessData, data: uboData} = useRetrieveData()

  let treeData = null

  let showLoader = false
  let renderPage = false

  useEffect(() => {
    try {
      if (jwt(id)){
        const decodedResult = jwt(id)
   
        const formInfo = encoding({
          ...decodedResult
        })
    
        let payload = {
          userInformation: formInfo
        }
    
        mutate(payload, {
          onSuccess: () => {
            console.log('success validate')
          },
        })
      }
    } catch(err) {
      renderPage = false
    }
  }, [id])

  useEffect(() => {
    try {
      if (jwt(id)){
        const decodedResult = jwt(id)
   
        const formInfo = encoding({
          ...decodedResult
        })
    
        let payload = {
          userInformation: formInfo
        }
    
        mutateData(payload, {
          onSuccess: () => {
            console.log('success retrieve')
          },
        })
      }
    } catch(err) {
      renderPage = false
    }
  }, [id])

  if (isSuccessData) {
    if (uboData.data.tree_data !== undefined) {
      treeData = uboData.data.tree_data
    }
  }

  if (isSuccess && isSuccessData) {
    showLoader = false
    renderPage = true
  }

  if (isLoading || isLoadingData) {
    showLoader = true
  }




  return (
    <div>
      {renderPage === true && treeData !== null ? 
        <Sidebar user_id={uboData.data.user_id} tree_data={treeData} initialSearch={false}/>
        :
        renderPage === true && treeData === null ?
        <EntityMain user_id={uboData.data.user_id} />
        :
        <ErrorPage />
      }

      {showLoader && 
        <div>
          <Loader message="Retrieving Information" />
        </div>
      }


    </div>
  )
}
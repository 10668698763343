import axios from "axios"

const nodeURL = String(process.env.REACT_APP_NODE_URL)

const api = axios.create({
    baseURL: "/api/",
    withCredentials: false,
})

const searchExistence = (payload) => api.post("s3/get/search", payload)
const searchSubExistence = (payload) => api.post("s3/get/sub-search", payload)
const retrieveData = (payload) => api.post("retrieve-data", payload)
const entitySearch = (payload) => api.post("ubo/entity/check", payload)
const expandCheck = (payload) => api.post("s3/get/expand-check", payload) 
const expandSubCheck = (payload) => api.post("s3/get/expand-subcheck", payload) 
const validateToken = (payload) => api.post("validate-token", payload) 
const downloadCompanyFile = (payload) => api.post("download/file", payload)

// React Flow & D3
const retrieveProfile = (payload) => api.post("retrieve-profile", payload)
const searchProfileExistence = (payload) => api.post("s3/get/profile-search", payload)
const searchSubProfileExistence = (payload) => api.post("s3/get/profile-subsearch", payload)
const expandMainProfile = (payload) => api.post("s3/get/expand-main-profile", payload)
const expandSubProfile = (payload) => api.post("s3/get/expand-sub-profile", payload)
const downloadCompanyProfile = (payload) => api.post("download/profile", payload)

// Owner
const getInfo = (payload) => api.post("ubo/get-info", payload)

const apis = {
    searchExistence,
    searchSubExistence,
    retrieveData,
    entitySearch,
    expandCheck,
    expandSubCheck,
    validateToken,
    downloadCompanyFile,
    
    // React Flow
    retrieveProfile,
    searchProfileExistence,
    searchSubProfileExistence,
    expandMainProfile,
    expandSubProfile,
    downloadCompanyProfile,

    // LHDN Owner
    getInfo,
}

export default apis
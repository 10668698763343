import React, { useEffect, useState } from 'react'
// import Tree from 'react-d3-tree'
// import './css/custom-tree.css'
import Sidebar from './Sidebar'
import { useParams } from 'react-router-dom'
import jwt from 'jwt-decode'
import { useValidateToken, useRetrieveProfile } from '../../api/Mutation/mutate'
import { encoding } from '../../api/Encryption/encryption'
import ErrorPage from './ErrorPage'
import Loader from './Loader'

export default function RetrieveCheck(){

  const { id } = useParams()
  const [paymentToken, setPaymentToken] = useState(null)
  const {mutate, isLoading, isSuccess} = useValidateToken()
  const {mutate: mutateData, isLoading: isLoadingData, isSuccess: isSuccessData, data: uboData} = useRetrieveProfile()
  const [dataDetail, setDataDetail] = useState(null)
  const [initialTreeData, setInitialTreeData] = useState(null)
  const [existingTreeData, setExistingTreeData] = useState(null)
  

  let treeData = null
  let showLoader = false
  let renderPage = false
  let initialData = null

  useEffect(() => {
    try {
      if (jwt(id)){
        const decodedResult = jwt(id)

        if (decodedResult.initialSearch) {
          let entityId = decodedResult.company_details.entityid+'-'+decodedResult.company_details.checkdigit
          setInitialTreeData(
            {
              node_data: [
                {
                  id: entityId+"-C-"+decodedResult.company_details.name,
                  profileName: decodedResult.company_details.name,
                  fontColor: "#ffffff",
                  attributes: {
                      category: decodedResult.company_details.type,
                      id: entityId,
                      newID: decodedResult.company_details.newformatregno
                  }
                }
                
              ],
              link_data: []
            }
          )
        }

        setDataDetail(decodedResult)

        const formInfo = encoding({
          ...decodedResult
        })
        

        let payload = {
          userInformation: formInfo
        }
    
        mutate(payload, {
          onSuccess: (res) => {
            console.log('success validate')
          },
        })
      }
    } catch(err) {
      renderPage = false
    }
  }, [id])

  useEffect(() => {
    try {
      if (jwt(id)){
        const decodedResult = jwt(id)
   
        if (decodedResult.initialSearch === false) {
          const formInfo = encoding({
            ...decodedResult
          })
      
          let payload = {
            userInformation: formInfo
          }

          mutateData(payload, {
            onSuccess: (res) => {
              setExistingTreeData(res.data.detail_data)
              setPaymentToken(res.data.payment_token)
              console.log('success retrieve check')
            },
          })
        }
      }
    } catch(err) {
      renderPage = false
    }
  }, [id])

  if (isSuccessData) {
    if (uboData.data.detail_data !== undefined) {
      treeData = uboData.data.detail_data
      // treeData.node_data = JSON.parse(treeData.node_data)
      // treeData.edge_data = JSON.parse(treeData.edge_data)
      // treeData.company_list = JSON.parse(treeData.company_list)

    }
  }
  
  if (isSuccess === true &&  dataDetail.company_details !== null) {
    // treeData = null
    initialData = dataDetail.company_details
    renderPage = true
  }


  if (isSuccess && isSuccessData) {
    showLoader = false
    renderPage = true
  }

  if (isLoading || isLoadingData) {
    showLoader = true
  }

  return (
    <div>

      {renderPage === true && treeData !== null ? 
        
        <Sidebar user_id={uboData.data.user_id} tree_data={treeData} initialSearch={false} payment_token={dataDetail.payment_token}/>
        :
        renderPage === true && treeData === null ?
        // <EntityMain user_id={uboData.data.user_id} payment_token={paymentToken}/>
        <Sidebar user_id={dataDetail.user_id} tree_data={initialTreeData} initialSearch={true} initialData={initialData} payment_token={dataDetail.payment_token}/>
        :
        <ErrorPage />
      }

      {showLoader && 
        <div>
          <Loader message="Retrieving Information" />
        </div>
      }


    </div>
  )
}
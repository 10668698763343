import React from "react"
import Network from "../components/Sigma/Network"

let sigmaRoute = [
    {
        name: "network page",
        href: "/network",
        component: <Network />
    }
]

export default sigmaRoute
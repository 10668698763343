import React, { useCallback, useState, useRef, useEffect } from 'react'
import { encoding } from '../../api/Encryption/encryption'
import { useSearchProfileExistence, useSearchSubProfileExistence, useExpandMainProfile, useExpandSubProfile, useDownloadCompanyProfile } from '../../api/Mutation/mutate'
import DialogModal from './DialogModal'
import Select from 'react-select'
import Loader from './Loader'
import { VscFilePdf } from "react-icons/vsc"
import { exportComponentAsJPEG } from 'react-component-export-image'
import { FaCircle, FaDownload, FaFileExport, FaSearch, FaListUl } from "react-icons/fa"
import { Graph } from "react-d3-graph"
import networkData from "./Data.js"
import { graphOption, linkTypeOption, nodeLabelPositionOption } from "./Option.js"
import './network.css'
import companyIcon from '../../images/company.png'
import mainIcon from '../../images/main.png'
import directorIcon from '../../images/director.png'


export default function Sidebar(props) {
  // FLOW
  const compIcon = "./berhad.svg"
  const indIcon = "./person.svg"
  const berhadIcon = "./multi.svg"
  const indColor = "#da1414"
  const compColor = "#1e2af0"
  const berhadColor = "#49ad1d" 

  let treeNode = null
  let treeLink = null
  let uboId = null
  let companyList = null
  let showButton = true

  let initialSearch = props.initialSearch
  let showLoader = false

  const [linkType, setLinkType] = useState('STRAIGHT')
  const [nodeLabelPosition, setNodeLabelPosition] = useState("bottom")
  const [allowStatic, setAllowStatic] = useState(false)
  const [allowCollapse, setAllowCollapse] = useState(true)
  const [allowFilter, setAllowFilter] = useState(0.2)
  const [allowRenderLabel, setAllowRenderLabel] = useState(true)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showSubPaymentModal, setShowSubPaymentModal] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [showSubSuccessModal, setShowSubSuccessModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [warningMessage, setWarningMessage] = useState("Error")

  // For modal popup
  const [currentCompany, setCurrentCompany] = useState({name: "", id: ""})

  // Set initial graph
  const [graphData, setGraphData] = useState({nodes: props.tree_data.node_data, link: props.tree_data.link_data})

  // Search initial profile existence
  const {mutate: checkMutate, isSuccess, data: checkResult} = useSearchProfileExistence()

  // Search next profile existence and so on
  const {mutate: subCheckMutate, isSuccess: subSuccess, data: subCheckResult} = useSearchSubProfileExistence()

  // Expand first initial profile
  const {mutate: expandMutate, isLoading: expandLoading, isSuccess: expandSuccess, data: expandResult} = useExpandMainProfile()

  // Expand next profile and so on
  const {mutate: subExpandMutate, isLoading: subExpandLoading, isSuccess: subExpandSuccess, data: subExpandResult} = useExpandSubProfile()

  // To download profile in PDF format
  const {mutate: downloadMutate, isLoading: downloadLoading, isSuccess: downloadSuccess, data: downloadResult} = useDownloadCompanyProfile()

  // D3 graph config
  const myConfig = {
    automaticRearrangeAfterDropNode: false, 
    collapsible: allowCollapse,
    directed: true,
    highlightDegree: 2,
    highlightOpacity: allowFilter,
    height: 700,
    width: 1230,
    staticGraph: allowStatic,
    panAndZoom: false,
    freezeAllDragEvents: false,
    nodeHighlightBehavior: true,
    linkHighlightBehavior: true,
    focusZoom: 1,
    maxZoom: 500,
    minZoom: 0.1,
    node: {
        labelPosition: nodeLabelPosition,
        size: 400,
        fontSize: 15,
        highlightFontSize: 15,
        highlightFontWeight: "bold",
        highlightStrokeWidth: "100%",
        highlightStrokeColor: "purple",
        viewGenerator: null,
        strokeColor: null,
        labelProperty: "profileName",
    },
    link: {
        color: "#dadada",
        type: linkType,
        strokeWidth: 2,
        fontSize: 8,
        opacity: 1,
        highlightColor: "#8d8c8c",
        highlightFontSize: 13,
        highlightFontWeight: "bold",
        renderLabel: allowRenderLabel,
        labelProperty: "label",
    },
    d3: {
        alphaTarget: 0.05,
        linkLength: 145,
        gravity: -610,
        linkStrength: 2,
    }
  }

  // list of downloadable company profile
  if (props.tree_data.company_list) {
    companyList = props.tree_data.company_list
  }

  // store ubo id
  if (props.tree_data.id) {
    uboId = props.tree_data.id
  }

  // checking to make sure tree data not null
  if (props.tree_data !== null) {
    treeNode =  props.tree_data.node_data
    treeLink = props.tree_data.link_data 
  }

  // function to expand node on right click
  const onRightClickNode = (event, nodeId, node) => {
    setCurrentCompany({name: node.profileName, id: node.attributes.newID, oldId: node.attributes.id})

    if ('attributes' in node === false || node.attributes.category !== 'C') {
      setShowModal(true)
    }
    else {
      let payload_data = {
        directory: node.attributes.id,
        sub_directory: null,
        payment_token: props.payment_token,
        parent_node: treeNode,
        parent_link: treeLink,
        parent_name: node.profileName,
        newId: node.attributes.newID,
        uboId: uboId,
        user_id: props.user_id,
        flow_id: node.attributes.id+"-"+node.attributes.category+"-"+node.profileName,
        company_list: companyList
      }

      console.log("payloadnew",payload_data)

      const formInfo = encoding({
        ...payload_data
      })

      let payload = {
        userInformation: formInfo
      }

      subCheckMutate(payload, {
        onSuccess: (res) => {
          console.log("Success sub check")
          if (res.data.exist) {
            setShowSubSuccessModal(true)
          }
          else {
            setShowSubPaymentModal(true)
          }
        },
        onError: (res) => {
          console.log(res)
          setShowWarning(true)
          setWarningMessage("Unable to expand this company, you may retry")
        }
      })
    }
  }

  // download ref
  const downloadRef = useRef()

  // sidebar side when show & hide
  const [changeGrid, setChangeGrid] = useState("h-fit grid grid-rows")
  const [changeFlex, setChangeFlex] = useState("hidden flex-auto bg-slate-100 border-2 text-slate-600 px-2 py-2")
  const [changeButtonShow, setChangeButtonShow] = useState("Graph Configuration")

  // to hide and show sidebar
  function handleSidebar() {
    if (changeButtonShow === "Graph Configuration") {
      setChangeButtonShow("Exit Configuration")
      setChangeGrid("h-screen grid grid-cols-custom-sidenav-layout")
      setChangeFlex("flex flex-col bg-slate-100 border-2 text-slate-600 px-2 py-2")
    }
    else {
      setChangeButtonShow("Graph Configuration")
      setChangeGrid("h-screen grid grid-rows")
      setChangeFlex("hidden flex-auto bg-slate-100 border-2 text-slate-600")
    }
  }

  // handle download profile on s3
  function handleDownload(companyDirectory) {
    let payload_data = {
      directory: companyDirectory
    }

    const formInfo = encoding({
      ...payload_data
    })

    let payload = {
      userInformation: formInfo
    }

    downloadMutate(payload, {
      onSuccess: (res) => {
        openInNewTab(res.data.temporaryUrl)
      },
    })
  }

  // auto open new tab when click download profile
  function openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer')
    if (newWindow) newWindow.opener = null
  }

  // function to check initial company existence on s3
  function handleCheck() {
    setCurrentCompany({name: props.initialData.name, id: props.initialData.newformatregno})
    let payload_data = {
      directory: props.initialData.entityid+"-"+props.initialData.checkdigit,
      company_name: props.initialData.name,
      flow_id: props.initialData.entityid+"-"+props.initialData.checkdigit+"-C-"+props.initialData.name,
      newId: props.initialData.newformatregno,
      sub_directory: null,
      user_id: props.user_id,
      payment_token: props.payment_token,
      app_name: props.app_name || 'linkage'
    }

    const formInfo = encoding({
      ...payload_data
    })

    let payload = {
      userInformation: formInfo
    }

    checkMutate(payload, {
      onSuccess: (res) => {
        if (res.data.exist) {
       
          setShowSuccessModal(true)
        }
        else {
          setShowPaymentModal(true)
        }
      },
      onError: () => {
        setShowWarning(true)
        setWarningMessage("This is company is not exist, please try other company")
      }
    })
  }
  // "https://img.icons8.com/ios-filled/100/FA5252/company.png"

  // Change node icon
  function handleIcon(allNode, allLink) {
    const newNode = allNode.map((item, index) => ({...item, key: item.id, svg: index<=0? mainIcon : item.attributes.category === "ROC" || item.attributes.category === "C" || item.attributes.category === "" ? companyIcon :  directorIcon}))
    setGraphData({nodes: newNode, links: allLink})
  }

  // function that handle initial expand 
  function handleExpand(proceedPay) {
    setShowPaymentModal(false)
    setShowSuccessModal(false)

    let payload_data = checkResult.data
    payload_data = {...payload_data, proceedPay: proceedPay, node_data: treeNode, link_data: treeLink}

    const formInfo = encoding({
      ...payload_data
    })

    let payload = {
      userInformation: formInfo
    }

    expandMutate(payload, {
      onSuccess: (res) => {
          console.log("res",res.data)
          console.log("Expanded")
        handleIcon(res.data.node_data, res.data.link_data)
        console.log("uboidres",res.data.ubo_id)
      },
      onError: () => {
        setShowWarning(true)
        setWarningMessage("Unable to Expand")
      }
    })
  }

  // function that handle sub expand
  function handleSubExpand(proceedPay) {
    setShowSubPaymentModal(false)
    setShowSubSuccessModal(false)
    let payload_data = subCheckResult.data
    payload_data = {...payload_data, proceedPay: proceedPay, company_list: companyList}

    const formInfo = encoding({
      ...payload_data
    })

    let payload = {
      userInformation: formInfo
    }

    subExpandMutate(payload, {
      onSuccess: (res) => {
        console.log("Sub Expanded")
        handleIcon(res.data.node_data, res.data.link_data)
      },
      onError: () => {
        setShowWarning(true)
        setWarningMessage("Unable to expand")
      }
    })
  }

  if (expandSuccess){
    showButton = false
    treeNode = expandResult.data.node_data
    treeLink = expandResult.data.link_data
    uboId = expandResult.data.ubo_id
    companyList = expandResult.data.company_list
    console.log(expandResult)
  }

  if (subExpandSuccess){
    treeNode = subExpandResult.data.node_data
    treeLink = subExpandResult.data.link_data
    companyList = subExpandResult.data.company_list
  }

  if (expandLoading || subExpandLoading) {
    showLoader = true
  }
  else {
    showLoader = false
  }

  // Initial Assign Value
  // useEffect(() => {
    
  // },[treeNode, treeEdge])
  
  // Link Type 
  // useEffect(() => {
  //   setEdges((eds) =>
  //   eds.map((edge) => {
  //       edge.type = linkType
  //       return edge
  //   })
  // )},[arrowType])

  // This for disable right click inspect on node
  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  // Initial Data
  useEffect(() => {
    if (initialSearch === true) {
      setCurrentCompany({name: props.initialData.name, id: props.initialData.newformatregno, oldId: props.initialData.entityid+"-"+props.initialData.checkdigit})
      let payload_data = {
        directory: props.initialData.entityid+"-"+props.initialData.checkdigit,
        company_name: props.initialData.name,
        flow_id: props.initialData.entityid+"-"+props.initialData.checkdigit+"-C-"+props.initialData.name,
        newId: props.initialData.newformatregno,
        sub_directory: null,
        user_id: props.user_id,
        payment_token: props.payment_token,
        app_name: props.app_name ||"linkage"
      }
  
      const formInfo = encoding({
        ...payload_data
      })
  
      let payload = {
        userInformation: formInfo
      }
  
      checkMutate(payload, {
        onSuccess: (res) => {
          if (res.data.exist) {
            setShowSuccessModal(true)
          }
          else {
            setShowPaymentModal(true)
          }
        },
        onError: () => {
          setShowWarning(true)
          setWarningMessage("This is company is not exist, please try other company")
        }
      })
    } 
    else {
      handleIcon(props.tree_data.node_data, props.tree_data.link_data)
    }
  }, [initialSearch])

  return (
    <div className={changeGrid}>

      <div className={changeFlex}>

        {/* Side Header */}
        <div className="incline-block mb-6 pb-4">
          <p className="text-xl font-bold pl-1 no-underline text-slate-600 ">My Dataplus Linkage</p>
        </div>

        {/* Side Menu */}
        <div>
          <div>
            <p className='text-slate-600 text-lg my-1 font-semibold'>Graph Type</p>
            <Select
              className="test"
              classNamePrefix="select"
              defaultValue={graphOption[0]}
              name="graph-type"
              options={graphOption}
              onChange={(choice)=> setAllowStatic(choice.value)}
            />
            {/* <button onClick={() => setAllowStatic(true)} type='button' className='mt-2 bg-slate-500 text-white text-sm px-1 py-1 rounded w-full'>
              Static
            </button>
            <button onClick={() => setAllowStatic(false)}  type='button' className='mt-2 bg-slate-500 text-white text-sm px-1 py-1 rounded w-full'>
              Drag & Drop
            </button> */}
          </div>
          <div>
            <p className='text-slate-600 text-lg my-1 font-semibold'>Link Type</p>
            <Select
              className="test"
              classNamePrefix="select"
              defaultValue={linkTypeOption[0]}
              name="graph-type"
              options={linkTypeOption}
              onChange={(choice)=> setLinkType(choice.value)}
            />
          </div>
          <div>
            <p className='text-slate-600 text-lg my-1 font-semibold'>Node Label Position</p>
            <Select
              className="test"
              classNamePrefix="select"
              defaultValue={nodeLabelPositionOption[0]}
              name="graph-type"
              options={nodeLabelPositionOption}
              onChange={(choice)=> setNodeLabelPosition(choice.value)}
            />
          </div>
          <div>
            <p className='text-slate-600 text-lg mt-6 font-semibold'>Collapsible</p>
            <button onClick={() => setAllowCollapse(!allowCollapse)} type='button' className='mt-1 bg-slate-500 text-white text-sm px-1 py-1 rounded w-full'>
              {allowCollapse === true ? "Disable":"Enable"}
            </button>
          </div>
          <div>
            <p className='text-slate-600 text-lg font-semibold'>Link Label</p>
            <button onClick={() => setAllowRenderLabel(!allowRenderLabel)} type='button' className='mt-1 bg-slate-500 text-white text-sm px-1 py-1 rounded w-full'>
              {allowRenderLabel === true ? "Disable":"Enable"}
            </button>
          </div>
          <div>
            <p className='text-slate-600 text-lg  font-semibold'>Node Filter</p>
            <button onClick={() => setAllowFilter(allowFilter === 0.2 ? 1 : 0.2)} type='button' className='mt-1 bg-slate-500 text-white text-sm px-1 py-1 rounded w-full'>
              {allowFilter === 0.2 ? "Disable":"Enable"}
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="inline-block text-gray-600 border-b-2 border-slate-300 pt-6 pb-2 px-6">
            <div className="inline w-56 text-gray-700 dark:text-gray-300 ">
              <button type="button" onClick={() => handleSidebar()}  className="text-white inline-flex w-fit bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 roboto font-light rounded text-xs px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">{changeButtonShow}<FaListUl className='ml-2 mt-1'/></button> 
              { showButton && 
                <button type="button" onClick={() => handleCheck()} className="text-white inline-flex w-fit bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 roboto font-light rounded text-xs px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Re-Check<FaSearch className='ml-2 mt-1'/></button>
              }
              <button type="button" onClick={() => setShowDownloadModal(true)}  className="text-white inline-flex w-fit bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 roboto font-light rounded text-xs px-5 py-2.5 mr-2 mb-2 dark:bg-amber-500 dark:hover:bg-amber-600 focus:outline-none dark:focus:ring-amber-700">Download<FaDownload className='ml-2 mt-1'/></button> 
              <button type="button" onClick={() => exportComponentAsJPEG(downloadRef)} className="text-white inline-flex w-fit bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 roboto font-light rounded text-xs px-5 py-2.5 mr-2 mb-2 dark:bg-amber-500 dark:hover:bg-amber-600 focus:outline-none dark:focus:ring-amber-700">Export as Image<FaFileExport className='ml-2 mt-1'/></button> 
            </div>
        </div>
        <div className="flex-1 my-4 mx-4 border-4 border-gray-300 rounded-xl border-double text-xs break-words font-mono">
          <div ref={downloadRef} id="mynetwork" className="layoutflow">
          
            <div className='inline-flex text-xs mt-4 ml-4 border-slate-300 rounded-md border-2 w-fit'>
              <i className='flex mx-1 mt-1 mb-1 font-light'><FaCircle className='mr-1' size={20} style={{color: "#000000"}} />Main</i>
              <i className='flex mx-1 mt-1 font-light'><FaCircle className='mr-1' size={20} style={{color: "#da1414"}} />Company</i>
              <i className='flex mx-1 mt-1 font-light'><FaCircle className='mr-1' size={20} style={{color: "#49ad1d"}} />Director/Shareholder</i>
            </div>

            <Graph
              id="graph-id"
              data={graphData}
              config={myConfig}
              // onMouseOverNode={onMouseOverNode}
              onRightClickNode={onRightClickNode}
            />
            
            {/* <ReactFlow
              ref={downloadRef}
              style={{ width:"100%", height: "65vh" }}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onNodeClick={onNodeClick}
              connectionLineType={ConnectionLineType.SmoothStep}
              fitView
            >
              <Controls />
            </ReactFlow> */}
          </div>
        </div>
      </div>

      {showModal &&
        <div onClick={() => setShowModal(false)}>
          <DialogModal mainError="Please choose other node" detailError="This is not a company" />
        </div>
      }

      {showWarning &&
        <div onClick={() => setShowWarning(false)}>
          <DialogModal mainError="Error, please check your balance or try again later" detailError={warningMessage} />
        </div>
      }

      {/*PAYMENT MODAL*/}

      {showPaymentModal &&
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-2">  
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-2">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blue-200 rounded-t">
                    <h3 className="text-3xl font-semibold text-blue-500">
                        Confirmation
                    </h3>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <p className="my-4 text-slate-800 text-lg leading-relaxed">
                        <b>{currentCompany.id ? currentCompany.id : null } {currentCompany.oldId && currentCompany.oldId.includes("(") === true ? currentCompany.oldId : currentCompany.oldId.includes(")") === false ? "("+currentCompany.oldId+")" : null } {currentCompany.name}</b>
                      </p>
                      <p className="my-4 text-slate-500 text-lg leading-relaxed">
                        This is a new profile payment is needed to expand, Click <b>"Pay"</b> to proceed with the payment and <b>RM15.00</b> will be deducted from your credit  
                      </p>
                      <button onClick={() => handleExpand(true)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Pay
                      </button>
                      <button onClick={() => setShowPaymentModal(false)}  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-700">
                        Cancel
                      </button>
                    </div>
                </div>
                </div>
            </div>
        </div>
      }

      {/*SUCCESS MODAL*/}

      {showSuccessModal &&
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-2">  
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-2">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-green-300 rounded-t">
                  <h3 className="text-3xl font-semibold text-green-500">
                    Exist
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-800 text-lg leading-relaxed">
                    <b>{currentCompany.id ? currentCompany.id : null } {currentCompany.oldId && currentCompany.oldId.includes("(") === true ? currentCompany.oldId : currentCompany.oldId.includes(")") === false ? "("+currentCompany.oldId+")" : null } {currentCompany.name}</b>
                  </p>
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                   Entity is available in repository as of <b>{checkResult.data.date}</b> Click <b>"Pay"</b> to get latest entity info and <b>RM15.00</b> of your credit will be deducted otherwise click <b>"Proceed"</b>
                  </p>
                  <button onClick={() => handleExpand(false)} className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-700">
                    Proceed
                  </button>
                  <button onClick={() => handleExpand(true)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                    Pay
                  </button>
                  <button onClick={() => setShowSuccessModal(false)}  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-700">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {/*SUB PAYMENT MODAL*/}

      {showSubPaymentModal &&
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-2">  
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-2">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blue-200 rounded-t">
                    <h3 className="text-3xl font-semibold text-blue-500">
                        Confirmation
                    </h3>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                    <p className="my-4 text-slate-800 text-lg leading-relaxed">
                      {/* <b>{currentCompany.id !== null && currentCompany.id !== "" ? currentCompany.id : "-" } ({currentCompany.oldId !== null && currentCompany.oldId !== "" ? currentCompany.oldId : "-" }) {currentCompany.name}</b> */}
                      <b>{currentCompany.id ? currentCompany.id : null } {currentCompany.oldId && currentCompany.oldId.includes("(") === true ? currentCompany.oldId : currentCompany.oldId.includes(")") === false ? "("+currentCompany.oldId+")" : null } {currentCompany.name}</b>
                    </p>
                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                      This is a new profile payment is needed to expand, Click <b>"Pay"</b> to proceed with the payment and <b>RM15.00</b> will be deducted from your credit  
                    </p>
                    <button onClick={() => handleSubExpand(true)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Pay
                    </button>
                    <button onClick={() => setShowSubPaymentModal(false)}  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-700">
                        Cancel
                    </button>
                    </div>
                </div>
                </div>
            </div>
        </div>
      }

      {/*SUB SUCCESS MODAL*/}

      {showSubSuccessModal &&
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-2">  
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-2">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-green-300 rounded-t">
                  <h3 className="text-3xl font-semibold text-green-500">
                    Exist
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-800 text-lg leading-relaxed">
                    {/* <b>{currentCompany.id !== null && currentCompany.id !== "" ? currentCompany.id : "-" } ({currentCompany.oldId !== null && currentCompany.oldId !== "" ? currentCompany.oldId : "-" }) {currentCompany.name}</b> */}
                    <b>{currentCompany.id ? currentCompany.id : null } {currentCompany.oldId && currentCompany.oldId.includes("(") === true ? currentCompany.oldId : currentCompany.oldId.includes(")") === false ? "("+currentCompany.oldId+")" : null } {currentCompany.name}</b>
                  </p>
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                   Entity is available in repository as of <b>{subCheckResult.data.date}</b> Click <b>"Pay"</b> to get latest entity info and <b>RM15.00</b> of your credit will be deducted otherwise click <b>"Proceed"</b>
                  </p>
                  <button onClick={() => handleSubExpand(false)} className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-700">
                    Proceed
                  </button>
                  <button onClick={() => handleSubExpand(true)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                    Pay
                  </button>
                  <button onClick={() => setShowSubSuccessModal(false)}  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-700">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {/* DOWNLOADER */}

      { showDownloadModal &&
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-2">  
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-2">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-blue-300 rounded-t">
                <h3 className="text-3xl font-semibold text-blue-600">
                  Download Company Profile
                </h3>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                {companyList &&
                companyList.map((item, index) => (
                  <p key={index} className="my-4 text-slate-500 text-xs leading-relaxed">
                    {item.name} <button onClick={() => handleDownload(item.directory)} className='float-right text-xl text-red-500'><VscFilePdf /></button>
                  </p>
                ))}
                <button onClick={() => setShowDownloadModal(false)}  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-700">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      }

      {/*LOADER*/}

      {showLoader && 
        <div>
          <Loader message="Processing request" />
        </div>
      }

    </div>
  )
}

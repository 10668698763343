import { Graph } from "react-d3-graph"
import networkData from "./Data.js"
import './network.css'
import { useState, useEffect, useCallback } from "react"

export default function Network() {

    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [refreshPage, setRefreshPage] = useState(false)
    const {graphData, setGraphData} = useState({networkData})
    const [renderLabel, setRenderLabel] = useState(true)

    const myConfig = {
        automaticRearrangeAfterDropNode: false,
        collapsible: true,
        directed: true,
        highlightDegree: 2,
        highlightOpacity: 0.2,
        // staticGraph: false,
        staticGraphWithDragAndDrop: false,
        panAndZoom: false,
        freezeAllDragEvents: false,
        width: window.innerWidth,
        height: window.innerHeight,
        nodeHighlightBehavior: true,
        linkHighlightBehavior: true,
        focusZoom: 1,
        maxZoom: 500,
        minZoom: 0.1,
        node: {
            labelPosition: "bottom",
            size: 400,
            fontSize: 13,
            highlightFontSize: 15,
            highlightFontWeight: "bold",
            highlightStrokeWidth: "100%",
            highlightStrokeColor: "purple",
            viewGenerator: null,
            strokeColor: null,
            labelProperty: "firstName"
        },
        link: {
            color: "#dadada",
            strokeWidth: 2,
            fontSize: 8,
            opacity: 1,
            highlightColor: "#8d8c8c",
            highlightFontSize: 13,
            highlightFontWeight: "bold",
            renderLabel: renderLabel,
            labelProperty: "label",
            semanticStrokeWidth: true,
        },
        d3: {
            alphaTarget: 0.05,
            linkLength: 145,
            gravity: -500,
            linkStrength: 2,
        }
    }

    function onMouseOverNode(node) {
        setRenderLabel(false)
    }

   function onMouseOutNode() {
        setRenderLabel(true)
   }

   useEffect(() => {
    
}, [])
    
    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])

    function onRightClickNode(node){
        setShowSuccessModal(true)
    }

    return (
        <div className="h-screen grid grid-rows">     
            <div className="flex flex-col">
                <div className="flex-1 my-4 mx-4 border-4 border-gray-300 rounded-xl border-double text-xs break-words font-mono">
                    <div className="layoutflow">

                        <Graph
                            id="graph-id"
                            data={networkData}
                            config={myConfig}
                            focusedNodeId="Big Dataworks Sdn Bhd"
                            // onMouseOverNode={onMouseOverNode}
                            onRightClickNode={onRightClickNode}
                        />
                    </div>
                </div>
            </div>

            {showSuccessModal &&
            <div>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-2">  
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                        <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-2">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-green-300 rounded-t">
                                <h3 className="text-3xl font-semibold text-green-500">
                                    Exist
                                </h3>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                                <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                Entity is available in repository as of <b>Dummy Date</b> Click <b>"Pay"</b> to get latest entity info and <b>RM15.00</b> of your credit will be deducted otherwise click <b>"Proceed"</b>
                                </p>
                                <button className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-700">
                                    Proceed
                                </button>
                                <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                    Pay
                                </button>
                                <button onClick={() => setShowSuccessModal(false)}  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-700">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}
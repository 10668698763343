import React from "react"
import MainPage from "../components/Tableau/MainPage"
// import Loader from "../components/Loader"
// import SuccessModal from "../components/SuccessModal"
// import FailModal from "../components/FailModal"
// import PaymentModal from "../components/PaymentModal"

let TableauRoute = [
    {
        name: "tableau page",
        href: "/report/:id",
        component: <MainPage />
    }
]

export default TableauRoute
import React, { useEffect, useRef } from 'react';
const {tableau} = window

export default function TableauProductSales() {
  const ref = useRef(null)
  const url =  "https://public.tableau.com/views/product_16557974799830/Productwthother?:language=en-US&publish=yes&:display_count=n&:showShareOptions=false&:origin=viz_share_link"

  const options = {
    hideTabs: true,
    hideToolbar: true,
  }

  function initViz(){
    new tableau.Viz(ref.current, url, options)
  }

  useEffect(() => {
    initViz()
  }, [])

  return (
    <div ref={ref} style={{width:'100%', height:'100vh'}}></div>
  );
}
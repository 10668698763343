import React from "react"
import Sidebar from "../components/Owner/Sidebar"
import RetrieveCheck from "../components/Owner/RetrieveCheck"

let LhdnOwnerRoute = [
    {
        name: "network main",
        href: "/lhdn-ubo",
        component: <Sidebar />
    },
    {
        name: "lhdn",
        href: "/lhdn/:id",
        component: <RetrieveCheck />
    },
]

export default LhdnOwnerRoute
import React, { useCallback, useState, useRef, useEffect } from 'react'
import { encoding } from '../../api/Encryption/encryption'
import { useSearchProfileExistence, useSearchSubProfileExistence, useExpandMainProfile, useExpandSubProfile, useDownloadCompanyProfile } from '../../api/Mutation/mutate'
import DialogModal from './DialogModal'
import Select from 'react-select'
import Loader from './Loader'
import { VscFilePdf } from "react-icons/vsc"
import { exportComponentAsJPEG } from 'react-component-export-image'
import { FaCircle, FaDownload, FaFileExport, FaSearch, FaListUl } from "react-icons/fa"
import { Graph } from "react-d3-graph"
import { graphOption, linkTypeOption, nodeLabelPositionOption } from "./Option.js"
import './network.css'


export default function Sidebar(props) {
  // FLOW
  const compIcon = "./berhad.svg"
  const indIcon = "./person.svg"
  const berhadIcon = "./multi.svg"
  const indColor = "#da1414"
  const compColor = "#1e2af0"
  const berhadColor = "#49ad1d" 

  let treeNode = null
  let treeLink = null
  let uboId = null
  let companyList = null
  let showButton = true

  let initialSearch = props.initialSearch
  let showLoader = false

  const [linkType, setLinkType] = useState('STRAIGHT')
  const [nodeLabelPosition, setNodeLabelPosition] = useState("bottom")
  const [allowStatic, setAllowStatic] = useState(false)
  const [allowCollapse, setAllowCollapse] = useState(false)
  const [allowFilter, setAllowFilter] = useState(0.2)
  const [allowRenderLabel, setAllowRenderLabel] = useState(true)

  const [showWarning, setShowWarning] = useState(false)
  const [warningMessage, setWarningMessage] = useState("Error")

  const [graphData, setGraphData] = useState({nodes: props.tree_data.node_data, links: props.tree_data.link_data})

  const myConfig = {
    automaticRearrangeAfterDropNode: false,
    collapsible: allowCollapse,
    directed: true,
    highlightDegree: 2,
    highlightOpacity: allowFilter,
    height: 700,
    width: 1230,
    staticGraph: allowStatic,
    panAndZoom: false,
    freezeAllDragEvents: false,
    nodeHighlightBehavior: true,
    linkHighlightBehavior: true,
    focusZoom: 1,
    maxZoom: 500,
    minZoom: 0.1,
    node: {
        labelPosition: nodeLabelPosition,
        size: 400,
        fontSize: 15,
        highlightFontSize: 15,
        highlightFontWeight: "bold",
        highlightStrokeWidth: "100%",
        highlightStrokeColor: "purple",
        viewGenerator: null,
        strokeColor: null,
        labelProperty: "profileName",
    },
    link: {
        color: "#dadada",
        type: linkType,
        strokeWidth: 2,
        fontSize: 8,
        opacity: 1,
        highlightColor: "#8d8c8c",
        highlightFontSize: 13,
        highlightFontWeight: "bold",
        renderLabel: allowRenderLabel,
        labelProperty: "label",
    },
    d3: {
        alphaTarget: 0.05,
        linkLength: 300,
        gravity: -600,
        linkStrength: 2,
    }
  }

  if (props.tree_data !== null) {
    treeNode = props.tree_data.node_data
    treeLink = props.tree_data.link_data 
  }

  const [changeGrid, setChangeGrid] = useState("h-fit grid grid-rows")
  const [changeFlex, setChangeFlex] = useState("hidden flex-auto bg-slate-100 border-2 text-slate-600 px-2 py-2")
  const [changeButtonShow, setChangeButtonShow] = useState("Graph Configuration")

  function handleSidebar() {
    if (changeButtonShow === "Graph Configuration") {
      setChangeButtonShow("Exit Configuration")
      setChangeGrid("h-screen grid grid-cols-custom-sidenav-layout")
      setChangeFlex("flex flex-col bg-slate-100 border-2 text-slate-600 px-2 py-2")
    }
    else {
      setChangeButtonShow("Graph Configuration")
      setChangeGrid("h-screen grid grid-rows")
      setChangeFlex("hidden flex-auto bg-slate-100 border-2 text-slate-600")
    }
  }

  return (
    <div className={changeGrid}>

      <div className={changeFlex}>

        {/* Side Header */}
        <div className="incline-block mb-6 pb-4">
          <p className="text-xl font-bold pl-1 no-underline text-slate-600 ">My Dataplus Linkage</p>
        </div>

        {/* Side Menu */}
        <div>
          <div>
            <p className='text-slate-600 text-lg my-1 font-semibold'>Graph Type</p>
            <Select
              className="test"
              classNamePrefix="select"
              defaultValue={graphOption[0]}
              name="graph-type"
              options={graphOption}
              onChange={(choice)=> setAllowStatic(choice.value)}
            />
            {/* <button onClick={() => setAllowStatic(true)} type='button' className='mt-2 bg-slate-500 text-white text-sm px-1 py-1 rounded w-full'>
              Static
            </button>
            <button onClick={() => setAllowStatic(false)}  type='button' className='mt-2 bg-slate-500 text-white text-sm px-1 py-1 rounded w-full'>
              Drag & Drop
            </button> */}
          </div>
          <div>
            <p className='text-slate-600 text-lg my-1 font-semibold'>Link Type</p>
            <Select
              className="test"
              classNamePrefix="select"
              defaultValue={linkTypeOption[0]}
              name="graph-type"
              options={linkTypeOption}
              onChange={(choice)=> setLinkType(choice.value)}
            />
          </div>
          <div>
            <p className='text-slate-600 text-lg my-1 font-semibold'>Node Label Position</p>
            <Select
              className="test"
              classNamePrefix="select"
              defaultValue={nodeLabelPositionOption[0]}
              name="graph-type"
              options={nodeLabelPositionOption}
              onChange={(choice)=> setNodeLabelPosition(choice.value)}
            />
          </div>
          {/* <div>
            <p className='text-slate-600 text-lg mt-6 font-semibold'>Collapsible</p>
            <button onClick={() => setAllowCollapse(!allowCollapse)} type='button' className='mt-1 bg-slate-500 text-white text-sm px-1 py-1 rounded w-full'>
              {allowCollapse === true ? "Disable":"Enable"}
            </button>
          </div> */}
          <div>
            <p className='text-slate-600 text-lg font-semibold'>Link Label</p>
            <button onClick={() => setAllowRenderLabel(!allowRenderLabel)} type='button' className='mt-1 bg-slate-500 text-white text-sm px-1 py-1 rounded w-full'>
              {allowRenderLabel === true ? "Disable":"Enable"}
            </button>
          </div>
          {/* <div>
            <p className='text-slate-600 text-lg  font-semibold'>Node Filter</p>
            <button onClick={() => setAllowFilter(allowFilter === 0.2 ? 1 : 0.2)} type='button' className='mt-1 bg-slate-500 text-white text-sm px-1 py-1 rounded w-full'>
              {allowFilter === 0.2 ? "Disable":"Enable"}
            </button>
          </div> */}
        </div>
      </div>

      <div className="flex flex-col">
        <div className="inline-block text-gray-600 border-b-2 border-slate-300 pt-6 pb-2 px-6">
            <div className="inline w-56 text-gray-700 dark:text-gray-300 ">
              <button type="button" onClick={() => handleSidebar()}  className="text-white inline-flex w-fit bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">{changeButtonShow}<FaListUl className='ml-2 mt-1'/></button>     
            </div>
        </div>
        <div className="flex-1 my-4 mx-4 border-4 border-gray-300 rounded-xl border-double text-xs break-words font-mono">
          <div id="mynetwork" className="layoutflow">
          
            {/* <div className='inline-block mt-4 ml-4 border-slate-300 rounded-md border-2 w-fit'>
              <i className='flex mx-1 mt-1 mb-1 font-bold'><FaCircle className='mr-1' size={20} style={{color: "#bfdbfe"}} />Start</i>
              <i className='flex mx-1 mt-1 font-bold'><FaCircle className='mr-1' size={20} style={{color: "#bbf7d0"}} />Entity</i>
              <i className='flex mx-1 mt-1 font-bold'><FaCircle className='mr-1' size={20} style={{color: "#fecaca"}} />Individual</i>
              <i className='flex mx-1 mt-1 mb-1 font-bold'><FaCircle className='mr-1' size={20} style={{color: "#fef3c7"}} />Remaining / Individual</i>
            </div> */}

            <Graph
              id="graph-id"
              data={graphData}
              config={myConfig}
              // onMouseOverNode={onMouseOverNode}
            />
            
          </div>
        </div>
      </div>

      {/*LOADER*/}

      {showLoader && 
        <div>
          <Loader message="Processing request" />
        </div>
      }

    </div>
  )
}

import React, { useState, useEffect } from "react";
import Pagination from "./Pagination"
import { useEntitySearch } from '../../api/Mutation/mutate'
import { encoding } from '../../api/Encryption/encryption'
import Sidebar from "./Sidebar";


export default function EntityMain(props) {
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(false)
  const [companyName, setCompanyName] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(10)
  const [pageMode, setPageMode] = useState(false)
  const [showPagination, setShowPagination] = useState(false)
  const {mutate, isLoading, isSuccess, data: entityData} = useEntitySearch()

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  // Tree Data
  const [treeData, setTreeData] = useState(null)
  const [initialData, setInitialData] = useState(null)

  function handleSubmit(event) {
    event.preventDefault()

    let payload_data = {
        company: companyName.toLowerCase()
    }

    const formInfo = encoding({
        ...payload_data
      })
  
    let payload = {
        userInformation: formInfo
    }
  
    mutate(payload, {
        onSuccess: (res) => {
          setPosts(res.data.results.data)
          setShowPagination(true)
        },
    })
  }

  function handleRender(event, result) {
    setInitialData(result)
    event.preventDefault()
    setTreeData(
      {
        all_data : {
          name: 'Begin',
          children: [
            {
              name: result.name
            }
          ]
        }
      }
   )
   setPageMode(true)
  }

  return (
    <div className="bg-white">
      {pageMode === false &&
      <div>
        <div className="inline-block text-gray-600 border-b-2 border-slate-300 pt-6 pb-2 px-6 mb-2">
          <div className="inline w-56 text-gray-700 dark:text-gray-300 ">
              
              <form onSubmit={(e) => handleSubmit(e)}>
                  <p className="inline-block text-xl mr-6 text-slate-800">
                    Company Search
                  </p>
                  <input
                  name="search"
                  className="inline-block border-0 mr-4 px-4 w-96 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow"
                  placeholder="Company Name"
                  required
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <button type="submit"  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Search</button>
              </form>
          </div>
        </div>
        <div className="bg-white">
          <ul>
              {currentPosts.map((post) => (
              <li
                  key={post.entityid}
                  className='text-sky-700 font-semibold text-md mb-2 p-2 border-slate-400 border-2'
              >
                  <button type="button" onClick={(e) => handleRender(e, post)}>{post.name}</button>
              </li>
              ))}
          </ul>
        </div>
        <div className="bg-slate-200">
        {showPagination &&
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={posts.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        }
        </div>
        
      </div>
      }

      {pageMode === true &&
      <div>
        <Sidebar user_id={props.user_id} tree_data={treeData} initialSearch={true} initialData={initialData}/>
      </div>
      }
            
    </div>
  )
}

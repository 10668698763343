import React from "react"
import GetAllOwner from "../components/UBO/GetAllOwner"
import HomePage from "../components/UBO/HomePage"
import Sidebar from "../components/ReactFlow/Sidebar"
import RetrieveCheck from "../components/ReactFlow/RetrieveCheck"
// import Loader from "../components/Loader"
// import SuccessModal from "../components/SuccessModal"
// import FailModal from "../components/FailModal"
// import PaymentModal from "../components/PaymentModal"

let userRoute = [
    {
        name: "ubo page",
        href: "/:id",
        component: <GetAllOwner />
    },
    {
        name: "home page",
        href: "/",
        component: <HomePage />
    },
    // {
    //     name: "react flow",
    //     href: "/linkage/:id",
    //     component: <RetrieveCheck />
    // },
    // {
    //     name: "ubo page",
    //     href: "/analytics/:id",
    //     component: <GetAllOwner />
    // },

    // {
    //     name: "loader",
    //     href: "/test/loader",
    //     component: <Loader message="Processing payment" />
    // },
    // {
    //     name: "loader",
    //     href: "/test/success",
    //     component: <SuccessModal message="Payment have been made successfully" />
    // },
    // {
    //     name: "loader",
    //     href: "/test/fail",
    //     component: <FailModal message="Payment failed, please check your balance" />
    // },
    // {
    //     name: "loader",
    //     href: "/test/payment",
    //     component: <PaymentModal />
    // },
    // {
    //     name: "loader",
    //     href: "/test/frame",
    //     component: <Frame />
    // },

]

export default userRoute
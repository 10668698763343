import React, { useState, useEffect, useRef } from 'react'
import Tree from 'react-d3-tree'
import { useCenteredTree } from './Helper'
import { useSearchExistence, useSearchSubExistence, useExpandCheck, useExpandSubCheck, useDownloadCompanyFile } from '../../api/Mutation/mutate'
import { encoding } from '../../api/Encryption/encryption'
import DialogModal from './DialogModal'
import Select from 'react-select'
import Loader from './Loader'
import { VscFilePdf } from "react-icons/vsc"
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
// import PaymentModal from '../components/PaymentModal'
// import FailModal from '../components/FailModal'
// import SuccessModal from '../components/SuccessModal'

const Sidebar = (props) => {
  const [orientation, setOrientation] = useState("horizontal")
  const [arrowType, setArrowType] = useState("straight")
  const [zoom, setZoom] = useState(true)
  // const [treeName, setTreeName] = useState("")
  const [sibling, setSibling] = useState(1)
  const [nonSibling, setNonSibling] = useState(3)
  const [dimensions, translate, containerRef] = useCenteredTree()
  const [showModal, setShowModal] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [filter, setFilter] = useState("all")
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showSubPaymentModal, setShowSubPaymentModal] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [showSubSuccessModal, setShowSubSuccessModal] = useState(false)
  const [showFailModal, setShowFailModal] = useState(false)
  const [warningMessage, setWarningMessage] = useState("Error")
  const [changeGrid, setChangeGrid] = useState("h-screen grid grid-rows")
  const [changeFlex, setChangeFlex] = useState("hidden flex-auto bg-slate-100 border-2 text-slate-600 px-6 py-6")
  const [changeButtonShow, setChangeButtonShow] = useState("Graph Configuration")
  const downloadRef = useRef()
  

  const {mutate: checkMutate, isSuccess, data: checkResult} = useSearchExistence()
  const {mutate: subCheckMutate, isSuccess: subSuccess, data: subCheckResult} = useSearchSubExistence()

  const {mutate: expandMutate, isLoading: expandLoading, isSuccess: expandSuccess, data: expandResult} = useExpandCheck()
  const {mutate: subExpandMutate, isLoading: subExpandLoading, isSuccess: subExpandSuccess, data: subExpandResult} = useExpandSubCheck()

  const {mutate: downloadMutate, isLoading: downloadLoading, isSuccess: downloadSuccess, data: downloadResult} = useDownloadCompanyFile()

  const options = [
    { value: 'all', label: 'All' },
    { value: 'shareholder', label: 'Shareholders' },
  ]

  let showLoader = false
  let treeResult = null
  let hideFilter = false
  let hideButton = false
  let hideInput = "text"
  let uboId = null
  let initialSearch = props.initialSearch
  
  let companyList

  if (props.tree_data.company_list) {
    companyList = JSON.parse(props.tree_data.company_list)
  }
  else {
    companyList = null
  }

  function handleOrientation(orient) {
    setOrientation(orient)
  }

  function handleArrow(arrow) {
    setArrowType(arrow)
  }

  function handleZoom(event) {
    if (event.target.checked){
      setZoom(true)
    }
    else{
      setZoom(false)
    }
  }

  if (props.tree_data !== null && initialSearch === false) {
    hideButton = true
    hideInput = "hidden"
    uboId = props.tree_data.id
    if (filter === "shareholder") {
      treeResult = JSON.parse(props.tree_data.shareholder_data)
    }
    else if (filter === "officer") {
      treeResult = JSON.parse(props.tree_data.director_data)
    }
    else {
      treeResult = JSON.parse(props.tree_data.all_data)
    }
  }

  if (props.tree_data !== null && initialSearch === true) {
    hideFilter = true
    hideInput = "text"
    hideButton = false
    uboId = props.tree_data.id
    if (filter === "shareholder") {
      treeResult = props.tree_data.shareholder_data
    }
    else if (filter === "officer") {
      treeResult = props.tree_data.director_data
    }
    else {
      treeResult = props.tree_data.all_data
    }
  }

  useEffect(() => {
    if (initialSearch === true) {
      let payload_data = {
        directory: props.initialData.entityid+"-"+props.initialData.checkdigit,
        company_name: props.initialData.name,
        newId: props.initialData.newformatregno,
        sub_directory: null,
        user_id: props.user_id
      }
  
      const formInfo = encoding({
        ...payload_data
      })
  
      let payload = {
        userInformation: formInfo
      }
  
      checkMutate(payload, {
        onSuccess: (res) => {
          if (res.data.exist) {
            setShowSuccessModal(true)
          }
          else {
            setShowPaymentModal(true)
          }
        },
        onError: () => {
          setShowWarning(true)
          setWarningMessage("This is company is not exist, please try other company")
        }
      })
    }
  }, [initialSearch])

  function handleCheck() {
    let payload_data = {
      directory: props.initialData.entityid+"-"+props.initialData.checkdigit,
      company_name: props.initialData.name,
      newId: props.initialData.newformatregno,
      sub_directory: null,
      user_id: props.user_id
    }

    const formInfo = encoding({
      ...payload_data
    })

    let payload = {
      userInformation: formInfo
    }

    checkMutate(payload, {
      onSuccess: (res) => {
        if (res.data.exist) {
          setShowSuccessModal(true)
        }
        else {
          setShowPaymentModal(true)
        }
      },
      onError: () => {
        setShowWarning(true)
        setWarningMessage("Unable to expand this company")
      }
    })
  }

  function handleSidebar() {
    if (changeButtonShow === "Graph Configuration") {
      setChangeButtonShow("Exit Configuration")
      setChangeGrid("h-screen grid grid-cols-custom-sidenav-layout")
      setChangeFlex("flex flex-col bg-slate-100 border-2 text-slate-600 px-6 py-2")
    }
    else {
      setChangeButtonShow("Graph Configuration")
      setChangeGrid("h-screen grid grid-rows")
      setChangeFlex("hidden flex-auto bg-slate-100 border-2 text-slate-600 px-6 py-2")
    }
  }

  function handleExpand(proceedPay) {
    setShowPaymentModal(false)
    setShowSuccessModal(false)

    let payload_data = checkResult.data
    payload_data = {...payload_data, proceedPay: proceedPay}

    const formInfo = encoding({
      ...payload_data
    })

    let payload = {
      userInformation: formInfo
    }

    expandMutate(payload, {
      onSuccess: () => {
        console.log("Expanded")
      },
      onError: () => {
        setShowWarning(true)
        setWarningMessage("Payment are not authorized, please check your balance and retry")
      }
    })
  }

  function handleSubCheck(nodeData) {

      if ('attributes' in nodeData.data === false || nodeData.data.attributes.category !== 'C') {
        setShowModal(true)
      }

      else {

        let payload_data = {
          directory: nodeData.data.attributes.id,
          sub_directory: null,
          parent: treeResult,
          parent_name: nodeData.data.name,
          newId: nodeData.data.attributes.newID,
          uboId: uboId
        }

        const formInfo = encoding({
          ...payload_data
        })

        let payload = {
          userInformation: formInfo
        }

        subCheckMutate(payload, {
          onSuccess: (res) => {
            if (res.data.exist) {
              setShowSubSuccessModal(true)
            }
            else {
              setShowSubPaymentModal(true)
            }
          },
          onError: () => {
            setShowWarning(true)
            setWarningMessage("Unable to expand this company, you may retry")
          }
        })
    }
  }

  function handleSubExpand(proceedPay) {
    setShowSubPaymentModal(false)
    setShowSubSuccessModal(false)

    let payload_data = subCheckResult.data
    payload_data = {...payload_data, proceedPay: proceedPay, company_list: companyList}

    const formInfo = encoding({
      ...payload_data
    })

    let payload = {
      userInformation: formInfo
    }

    subExpandMutate(payload, {
      onSuccess: () => {
        console.log("Sub Expanded")
      },
      onError: () => {
        setShowWarning(true)
        setWarningMessage("Payment are not authorized, please check your balance and retry")
      }
    })
  }

  function handleDownload(companyDirectory) {
    let payload_data = {
      directory: companyDirectory
    }

    const formInfo = encoding({
      ...payload_data
    })

    let payload = {
      userInformation: formInfo
    }

    downloadMutate(payload, {
      onSuccess: (res) => {
        openInNewTab(res.data.temporaryUrl)
      },
    })
  }

  function openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }


  if (expandSuccess) {
    uboId = expandResult.data.ubo_id
    hideButton = true
    hideInput = "hidden"
    initialSearch = false
    hideFilter = false

    if (filter === "shareholder") {
      treeResult = expandResult.data.shareholder_tree
    }
    else if (filter === "officer") {
      treeResult = expandResult.data.directory_tree
    }
    else {
      treeResult = expandResult.data.tree_data
    }

    companyList = expandResult.data.company_list
  }

  if (expandLoading || subExpandLoading) {
    showLoader = true
  }
  else {
    showLoader = false
  }

  if (subExpandSuccess === true) {
    if (filter === "shareholder") {
      treeResult = subExpandResult.data.shareholder_tree
    }
    else if (filter === "officer") {
      treeResult = subExpandResult.data.directory_tree
    }
    else {
      treeResult = subExpandResult.data.tree_data
    }

    companyList = subExpandResult.data.company_list

  }

  return (
    <div className={changeGrid}>

      <div className={changeFlex}>

        {/* Side Header */}
        <div className="incline-block ml-1 pb-4">
          <p className="text-xl font-bold pl-1 no-underline text-slate-600 ">My UBO Tree</p>
          {props.tree_data !== null &&
            <p className="uppercase text-lg font-bold pl-1 no-underline text-slate-600 ">{props.tree_data.name}</p>
          }
          <div className='mt-4'>
            <button className="bg-blue-600 rounded-full text-white w-full mt-3">Start</button>
            <button className="bg-green-400 rounded-full text-white btn-sm w-full mt-3">Expandable</button>
            <button className="bg-red-400 rounded-full text-white btn-sm w-full mt-3">End</button>
          </div>
        </div>

        {/* Side Menu */}
        <div>
          <div>
            <p className='text-slate-600 text-lg my-1 font-semibold'>Orientation</p>
            <button onClick={() => handleOrientation("horizontal")} type='button' className='mt-2 bg-slate-600 text-white text-sm px-4 py-1 rounded w-full'>
              Horizontal
            </button>
            <button  onClick={() => handleOrientation("vertical")} type='button' className='mt-2 bg-slate-600 text-white text-sm px-4 py-1 rounded w-full'>
              Vertical
            </button>
          </div>
          <div>
            <p className='text-slate-600 text-lg my-1 font-semibold'>Arrow Type</p>
            <button onClick={() => handleArrow("diagonal")} type='button' className='mt-2 bg-slate-600 text-white text-sm px-4 py-1 rounded w-full'>
              Diagonal
            </button>
            <button onClick={() => handleArrow("elbow")}  type='button' className='mt-2 bg-slate-600 text-white text-sm px-4 py-1 rounded w-full'>
            Elbow
            </button>
            <button onClick={() => handleArrow("straight")}  type='button' className='mt-2 bg-slate-600 text-white text-sm px-4 py-1 rounded w-full'>
              Straight
            </button>
            <button onClick={() => handleArrow("step")}  type='button' className='mt-2 bg-slate-600 text-white text-sm px-4 py-1 rounded w-full'>
              Step
            </button>
          </div>
          <div>
            <p className='text-slate-600 text-lg my-1 font-semibold'>Separation</p>
            <div>
              <input type="number" placeholder="Siblings" onChange={(e) => setSibling(parseInt(e.target.value))} className="mt-2 rounded w-full border-2 border-slate-400 text-gray-600" />
            </div>
            <div>
              <input type="number" placeholder="Non-Siblings" onChange={(e) => setNonSibling(parseInt(e.target.value))} className="mt-2 rounded w-full border-2 border-slate-400 text-gray-600" />
            </div>
          </div>
          <div>
            <p className='text-slate-600 text-lg my-1 font-semibold'>Enable Features</p>
            <div>
              <input type="checkbox" checked={zoom} onChange={(e) => handleZoom(e)} className="mt-1 h-5 w-5 text-gray-600" /><span className="ml-2 text-gray-700">Zoomable</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="inline-block text-gray-600 border-b-2 border-slate-300 pt-6 pb-2 px-6">
            <div className="inline w-56 text-gray-700 dark:text-gray-300 ">
              <button type="button" onClick={() => handleSidebar()}  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">{changeButtonShow}</button> 
              <button type="button" onClick={() => setShowDownloadModal(true)}  className="text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-500 dark:hover:bg-amber-600 focus:outline-none dark:focus:ring-amber-700">Download</button> 
              <button type="button" onClick={() => handleCheck()} hidden={hideButton} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Re-Check</button>
              <button type="button" onClick={() => exportComponentAsJPEG(downloadRef)} className="text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-500 dark:hover:bg-amber-600 focus:outline-none dark:focus:ring-amber-700">Export as Image</button> 
              <Select isDisabled={hideFilter} className="inline-block ml-2" placeholder="Filter by" options={options} onChange={(e) => setFilter(e.value)} />
              
            </div>
        </div>
        <div className="flex-1 my-4 mx-4 border-4 border-gray-300 rounded-xl border-double text-xs break-words font-mono" ref={containerRef}>

          {treeResult !== null &&
            <Tree
            ref={downloadRef}
            data={treeResult}
            rootNodeClassName="node__root"
            branchNodeClassName="node__branch"
            leafNodeClassName="node__leaf"
            pathFunc={arrowType}
            orientation={orientation}
            nodeSize={{ x: 150, y: 100 }}
            zoomable={zoom}
            collapsible={false}
            dimensions={dimensions}
            translate={translate}
            separation={{ siblings: sibling, nonSiblings: nonSibling }}
            onNodeClick={handleSubCheck}
            />
          }
        </div>
      </div>

      {showModal &&
        <div onClick={() => setShowModal(false)}>
          <DialogModal mainError="Please choose other node" detailError="This is not a company" />
        </div>
      }

      {showWarning &&
        <div onClick={() => setShowWarning(false)}>
          <DialogModal mainError="Error" detailError={warningMessage} />
        </div>
      }

      {/*PAYMENT MODAL*/}

      {showPaymentModal &&
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-2">  
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-2">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blue-200 rounded-t">
                    <h3 className="text-3xl font-semibold text-blue-500">
                        Confirmation
                    </h3>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                      This is a new profile payment is needed to expand, Click <b>"Pay"</b> to proceed with the payment and <b>RM15.00</b> will be deducted from your credit  
                    </p>
                    <button onClick={() => handleExpand(true)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                      Pay
                    </button>
                    <button onClick={() => setShowPaymentModal(false)}  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-700">
                      Cancel
                    </button>
                    </div>
                </div>
                </div>
            </div>
        </div>
      }

      {/*SUCCESS MODAL*/}

      {showSuccessModal &&
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-2">  
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-2">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-green-300 rounded-t">
                  <h3 className="text-3xl font-semibold text-green-500">
                    Exist
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                   Entity is available in repository as of <b>{checkResult.data.date}</b> Click <b>"Pay"</b> to get latest entity info and <b>RM15.00</b> of your credit will be deducted otherwise click <b>"Proceed"</b>
                  </p>
                  <button onClick={() => handleExpand(false)} className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-700">
                    Proceed
                  </button>
                  <button onClick={() => handleExpand(true)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                    Pay
                  </button>
                  <button onClick={() => setShowSuccessModal(false)}  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-700">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {/*SUB PAYMENT MODAL*/}

      {showSubPaymentModal &&
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-2">  
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-2">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blue-200 rounded-t">
                    <h3 className="text-3xl font-semibold text-blue-500">
                        Confirmation
                    </h3>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                      This is a new profile payment is needed to expand, Click <b>"Pay"</b> to proceed with the payment and <b>RM15.00</b> will be deducted from your credit  
                    </p>
                    <button onClick={() => handleSubExpand(true)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Pay
                    </button>
                    <button onClick={() => setShowSubPaymentModal(false)}  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-700">
                        Cancel
                    </button>
                    </div>
                </div>
                </div>
            </div>
        </div>
      }

      {/*SUB SUCCESS MODAL*/}

      {showSubSuccessModal &&
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-2">  
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-2">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-green-300 rounded-t">
                  <h3 className="text-3xl font-semibold text-green-500">
                    Exist
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                   Entity is available in repository as of <b>{subCheckResult.data.date}</b> Click <b>"Pay"</b> to get latest entity info and <b>RM15.00</b> of your credit will be deducted otherwise click <b>"Proceed"</b>
                  </p>
                  <button onClick={() => handleSubExpand(false)} className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-700">
                    Proceed
                  </button>
                  <button onClick={() => handleSubExpand(true)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                    Pay
                  </button>
                  <button onClick={() => setShowSubSuccessModal(false)}  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-700">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {/* DOWNLOADER */}

      { showDownloadModal &&
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-2">  
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-2">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-blue-300 rounded-t">
                <h3 className="text-3xl font-semibold text-blue-600">
                  Download Company Profile
                </h3>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                {companyList &&
                companyList.map((item, index) => (
                  <p key={index} className="my-4 text-slate-500 text-xs leading-relaxed">
                    {item.name} <button onClick={() => handleDownload(item.directory)} className='float-right text-xl text-red-500'><VscFilePdf /></button>
                  </p>
                ))}
                <button onClick={() => setShowDownloadModal(false)}  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-700">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      }

      {/*LOADER*/}

      {showLoader && 
        <div>
          <Loader message="Processing request" />
        </div>
      }

    </div>
  )
}

export default Sidebar
const sizing = null
const compIcon = "./berhad.svg"
const userIcon = "./person.svg"
const berhadIcon = "./multi.svg"
const indColor = "#da1414"
const compColor = "#1e2af0"
const berhadColor = "#49ad1d"

const data = {
    nodes: [
        { 
            id: "Big Dataworks Sdn Bhd",
            firstName: "Faizal",
            fontColor: compColor,
            labelProperty: "firstName",
            size: sizing,
            svg: compIcon,
            attribute: {
                category: "A",
                id: "B",
                newID: "C"
            }
        }, 
        { 
            id: "Big Dataworks Sdn Bhd2",
            firstName: "Faizal",
            fontColor: compColor,
            labelProperty: "firstName",
            size: sizing,
            svg: compIcon,
            attribute: {
                category: "A",
                id: "B",
                newID: "C"
            }
        },
        { 
            id: "A Sdn Bhd",
            fontColor: compColor,
            size: sizing,
            svg: compIcon
        }, 
        { 
            id: "B Sdn Bhd",
            fontColor: compColor,
            size: sizing,
            svg: compIcon
        }, 
        { 
            id: "C Sdn Bhd",
            color: "blue",
            fontColor: compColor,
            size: sizing,
            svg: compIcon
        }, 
        { 
            id: "D Sdn Bhd",
            color: "blue",
            fontColor: compColor,
            size: sizing,
            svg: compIcon
        }, 
        { 
            id: "E Sdn Bhd",
            color: "blue",
            fontColor: compColor,
            size: sizing,
            svg: compIcon
        }, 
        { 
            id: "Sheriza Zakaria",
            color: "red",
            fontColor: indColor,
            size: sizing,
            svg: userIcon 
        }, 
        { 
            id: "En Musa",
            color: "red",
            fontColor: indColor,
            size: sizing,
            svg: userIcon 
        }, 
        { 
            id: "En Faiz",
            color: "red",
            fontColor: indColor,
            size: sizing,
            svg: userIcon 
        }, 
        { 
            id: "En Fazrul",
            color: "red",
            fontColor: indColor,
            size: sizing,
            svg: userIcon 
        }, 
        { 
            id: "Puncak Tegap",
            color: "blue",
            fontColor: compColor,
            size: sizing,
            collapse: false,
            svg: compIcon
        },
        { 
            id: "Puncak Semangat",
            color: "blue",
            fontColor: compColor,
            size: sizing,
            svg: compIcon
        },
        { 
            id: "Puncak Semangat",
            color: "blue",
            fontColor: compColor,
            size: sizing,
            svg: compIcon
        },
        { 
            id: "A Berhad",
            color: "blue",
            fontColor: berhadColor,
            size: sizing,
            svg: berhadIcon
        },
        { 
            id: "B Berhad",
            color: "blue",
            fontColor: berhadColor,
            size: sizing,
            svg: berhadIcon
        },
        { 
            id: "C Berhad",
            color: "blue",
            fontColor: berhadColor,
            size: sizing,
            svg: berhadIcon
        },
        { 
            id: "Syed Azmin",
            color: "red",
            fontColor: indColor,
            size: sizing,
            svg: userIcon   
        },
    ],
    links: [
        { 
            source: "Big Dataworks Sdn Bhd", 
            target: "Sheriza Zakaria",
            label: "Director" 
        },
        { 
            source: "Big Dataworks Sdn Bhd", 
            target: "Puncak Semangat",
            label: "Company"  
        },
        { 
            source: "Big Dataworks Sdn Bhd", 
            target: "En Musa",
            label: "Director"   
        },
        { 
            source: "Big Dataworks Sdn Bhd", 
            target: "En Faiz",
            label: "Director"   
        },
        { 
            source: "Big Dataworks Sdn Bhd", 
            target: "Big Dataworks Sdn Bhd2",
            label: "Director"   
        },
        { 
            source: "Big Dataworks Sdn Bhd", 
            target: "En Fazrul",
            label: "Director"   
        },
        { 
            source: "Puncak Semangat", 
            target: "Puncak Tegap",
            label: "Company"  
        },
        { 
            source: "Puncak Semangat", 
            target: "A Berhad",
            label: "Berhad"  
        },
        { 
            source: "Puncak Semangat", 
            target: "B Berhad",
            label: "Berhad"  
        },
        { 
            source: "Puncak Semangat", 
            target: "C Berhad",
            label: "Berhad"  
        },
        { 
            source: "Puncak Tegap", 
            target: "Syed Azmin",
            label: "Director"   
        },
        { 
            source: "Puncak Tegap", 
            target: "A Sdn Bhd",
            label: "Company"  
        },
        { 
            source: "Puncak Tegap", 
            target: "B Sdn Bhd",
            label: "Company"  
        },
        { 
            source: "Puncak Tegap", 
            target: "C Sdn Bhd",
            label: "Company"  
        },
        { 
            source: "Puncak Tegap", 
            target: "D Sdn Bhd",
            label: "Company"  
        },
        { 
            source: "Puncak Tegap", 
            target: "E Sdn Bhd",
            label: "Company"  
        }

        
    ],
}

export default data